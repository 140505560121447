import React from 'react';

const ClosedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 24 24"
    aria-label="closed"
  >
    <path
      aria-label="closed"
      d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"
    />
  </svg>
);

export default ClosedSvg;
